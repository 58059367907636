.dashbord-card {
  /* neo morohism card design */
  background-color: #ffffffb7;
  border-radius: 10px;
  box-shadow: 5px 3px 20px 1px #2064d148;
  padding: 20px;
  min-height: 100%;
}
.dashbord-card-2 {
  /* neo morohism card design */
  border-radius: 10px;
  padding: 10px;
  /* make 3 *2 grid coloumns responsively */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}
.dashboard-card-title {
  font-size: 18px;
  font-weight: bold;
  color: rgb(55, 66, 81);
  margin-bottom: 10px;
}

.check-option {
  background-color: aqua !important;
  padding: 15px !important;
}

.dashboard-text-point {
  font-size: 16px;
  font-weight: 400;
  color: rgb(1, 14, 33);
  margin-bottom: 10px;
}
.chekin-bar {
  /* background: rgb(255, 255, 255); */
  /* background: linear-gradient(43deg, rgb(0, 174, 255) 0%, rgb(136, 255, 251) 100%); */

  display: grid;
  /* place-items: center !important; */
  border-radius: 10px;
  /* background-image: url('bg-grad.jpg'); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  /* box-shadow: 0px 5px 25px 0px #2d6c8539; */
}

.attendence-form-card {
  display: grid;
  align-content: space-around;
  justify-content: center;
  text-align: center;
}
.main-clock {
  font-size: 50px;
  font-weight: 600;
  color: #253c50 !important;
}

.event-item {
  display: flex;
  gap: 15px;
  margin: 10px 0;
  align-items: center;
  background-color: #eff3f7;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 5px 3px 10px 1px #2064d148;
  /* min-width: 320px; */
}

.event-item-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  box-shadow: 0px 0px 0px 2px #0a67fca5;
}
.event-user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.event-item-desc {
  font-size: 12px;
  color: #253c50;
}
.event-item-title {
  font-size: 14px;
  font-weight: 600;
  color: #253c50;
}
.event-item-date {
  width: 100px;
  text-align: center;
  background-color: rgba(255, 0, 0, 0.402);
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  display: grid;
  place-items: center;
  box-shadow: 5px 3px 10px 1px #d1292048;
}
.anniversary {
  background: rgb(101, 255, 209);
  background: linear-gradient(43deg, rgba(101, 255, 209, 1) 0%, rgba(107, 242, 255, 1) 100%);
  box-shadow: 5px 3px 10px 1px #16d4f58a;
}
.no-data-events {
  background-color: #eff3f7;
  min-height: 60px;
  display: grid;
  place-items: center;
  border-radius: 10px;
  box-shadow: 5px 3px 10px 1px #0e3b7948;
  font-weight: 600;
}

.dashbord-news-card {
  background-color: #e9ecef5f;
  min-height: 150px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #e9ecef9d;
  box-shadow: 0px 5px 0px 0px #2064d1c5;
  transition: all 0.3s ease-in-out;
}

.dashbord-news-card:hover {
  box-shadow: 0px 10px 0px 0px #2065d1;
  background-color: #d8ecffd4;
}

.dashboard-news-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-news-card-title {
  font-size: 18px;
  font-weight: 600;
  color: #253c50;
  margin-bottom: 10px;
  line-height: 200%;
}
.dashboard-news-card-cat {
  font-size: 14px;
  font-weight: 600;
  color: #253c50;
  background: linear-gradient(43deg, rgba(101, 255, 209, 1) 0%, rgba(107, 242, 255, 1) 100%);
  box-shadow: 5px 3px 10px 1px #16d4f58a;
  padding: 2px 10px;
}
.news-view-more {
  text-align: center;
  padding: 15px;
  margin: 30px 0px;
  background-color: #e9ecef53;
}
