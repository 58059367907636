.cal-wrap {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 5px 3px 20px 1px #2064d148;
  font-size: 12px;
  min-height: 230px;
  width: min(350px, 100%);
}

.cal-head {
  font-size: 16px;
  padding: 5px 10px 15px 10px;
  font-weight: bold;
}

.cal-day-bar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 10px;
}
.cal-dn {
  width: 30px;
  height: 30px;
  text-align: center;
}
.cal-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 10px;
}

.cal-day-btn {
  width: 25px;
  height: 25px;
  border: none;
  background-color: rgba(231, 235, 248, 0);
  border-radius: 50%;
  transition: background-color 0.2s ease-in 0s, box-shadow 0.5s ease-in-out 0.1s;
}
.cal-day-btn:hover {
  background-color: rgb(161, 229, 244);
  color: rgb(0, 30, 69);
  box-shadow: 0px 0px 0px 3px rgba(193, 245, 255, 0.918);
}
.cal-day-yl {
  background-color: rgb(255, 167, 16);
  box-shadow: 0px 0px 0px 3px rgba(250, 201, 54, 0.918), 0px 0px 10px 3px rgba(250, 201, 54, 0.918);
}
.cal-day-red {
  background-color: rgb(250, 61, 61);
  box-shadow: 0px 0px 0px 3px rgba(250, 127, 127, 0.918), 0px 0px 10px 3px rgba(250, 127, 127, 0.918);
  color: #fff;
}
.cal-day-blue {
  background-color: rgb(61, 177, 250);
  box-shadow: 0px 0px 0px 3px rgba(127, 203, 250, 0.918), 0px 0px 10px 3px rgba(127, 203, 250, 0.918);
  color: #fff;
}
.cal-day-sun {
  color: red;
}

.cal-day-now {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 0px 3px rgba(82, 243, 194, 0.918);
  color: rgb(4, 56, 7);
}
.cal-day-green {
  background-color: rgb(45, 222, 112);
  box-shadow: 0px 0px 0px 3px rgba(82, 243, 194, 0.918);
  color: rgb(4, 56, 7);
}

.cal-month-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 10px;
}
.cal-year-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 10px;
}
.cal-title-btn {
  border: none;
  background-color: rgba(231, 235, 248, 0);
  font-size: 16px;
  font-weight: bold;
}
.cal-month-btns {
  border: none;
  background-color: rgba(20, 126, 255, 0.336);
  height: 30px;
  border-radius: 5px;
}
