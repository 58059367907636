.heat-map {
  display: flex;
  place-items: center;
  overflow-x: auto;
}

.heatmap-box {
  background-color: #98d7fe2f;
  border-radius: 5px;
  margin: 5px;
  border: 1px solid #ffffff;
  margin: 2px;
  cursor: pointer;
  position: relative;
  /* font-size: 6px; */
}

.heatmap-box:hover {
  background-color: rgba(0, 157, 255, 0.991) !important;
  border: 1px solid #646464;
  z-index: 100;
}

.heatmap-box:hover:after {
  content: attr(data-tip);
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 999;
  background-color: #333;
  color: #ffffff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
}

.hb-present {
  background-color: #9affbd !important;
}
.hb-present-2 {
  background-color: #25f06c !important;
}

.hb-present-3 {
  background-color: #13913f !important;
}

.hb-present-4 {
  background-color: #046928 !important;
}

.hb-late {
  background-color: #ffda0a !important;
}

.yellow-border {
  box-shadow: inset 0px 0px 0px 2px #f0ce0e !important;
}

.hb-absent {
  background-color: #ff7f7f !important;
}

.hb-sunday {
  background-color: #646161cf !important;
}

.day-text {
  display: flex;
  flex-direction: column;
  justify-items: end;
  height: 100%;
}

.day-text-item {
  font-size: 10px;
  height: 20px;
  width: 20px;
  display: grid;
  place-items: center;
  color: rgb(176, 176, 176);
}

.month-text {
  width: 1080px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}

.month-text-item {
  width: 85px;
  margin: 1px;
  display: grid;
  place-items: center;
  font-size: 12px;
  border-right: 2px solid #c2c2c2;
  color: rgb(176, 176, 176);
}

.overflow-auto {
  overflow-x: auto;
  display: grid;
  place-items: center;
}
.heatmap-desc {
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 90%;
}

.heatmap-legend {
  display: flex;
  gap: 20px;
  font-size: 12px;
  margin: 20px 0px;
}

.legend-box {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.legend-item {
  display: flex;
  gap: 5px;
  align-items: center;
}
