/* scroll bar style */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* scroll bar style */

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'Select file';
  display: inline-block;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 10pt;
  text-align: center;
}

.custom-file-input {
  width: 140px;
  height: 40px;
  overflow: hidden;
  background-color: #2065d1;
  color: #ffffff;
  padding: 10px 5px;
  border-radius: 10px;
  display: grid;
  place-items: center;
  box-shadow: 5px 7px 10px 2px #2064d148;
  transition: all 0.2s linear;
}
.custom-file-input:hover {
  background-color: #103996;
}

.custom-file-inputt {
  width: 100%;
  height: 40px;
  overflow: hidden;
  background-color: #2065d1;
  color: #ffffff;
  padding: 10px 5px;
  border-radius: 10px;
  display: grid;
  place-items: center;
  box-shadow: 5px 7px 10px 2px #2064d148;
  transition: all 0.2s linear;
}

.custom-file-inputt:hover {
  background-color: #103996;
}

.custom-file-inputt::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-inputt::before {
  content: 'Select file';
  display: inline-block;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 10pt;
  text-align: center;
}

.imgupload-company {
  background-color: transparent;
  color: #4a4a4b;
  border: 2px solid #7f7f80;
}

.imgupload-company:hover {
  background-color: transparent;
}

.img-upload-company {
  background-color: transparent;
  color: #4a4a4b;
  border: 1px solid #7f7f80;
}

.img-upload-company:hover {
  background-color: transparent;
}

.user-img {
  height: 160px;
  width: 140px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  border: #475673 2px solid;
  box-shadow: 5px 3px 20px 1px #2064d148;
  background-color: #edf2f599;
}

.user-img1 {
  height: 300px;
  width: 100%;
  border-radius: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* border: #475673 2px solid; */
  /* box-shadow: 5px 3px 20px 1px #2064d148; */
  /* background-color: #edf2f599; */
}

.news-desc {
  overflow-x: hidden;
  line-height: 2.2;
}

.user-img2 {
  margin-bottom: 15px;
  height: 230px;
  width: 100%;
  border-radius: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* background-color: #edf2f599; */
}
.user-img-lg {
  height: 260px;
  width: 200px;
}

.img-logo {
  height: 90px;
  width: 280px;
  border-radius: 1%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.react-loading {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
/* .button-sec {
  position: fixed;
  top: 70px;
  right: 15px;
} */

.bg-waring {
  background-color: #ffa20049;
}

.bg-waring-2 {
  background-color: #ff7700ba;
}

.loc-not-available-wrap {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #c9e4fe5a;
  z-index: 10000;
  overflow: hidden !important;
  backdrop-filter: blur(7px);
  display: grid;
  place-items: center;
}
.loc-img {
  height: 300px;
  padding: 20px;
}

.loc-err-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 5px 3px 20px 1px #2064d148;
  padding: 30px;
  text-align: center;
  color: #fd4141;
}
.company-address {
  width: 40%;
}

.title-bar {
  border-bottom: #3f4042da 2px solid;
}
.text-blue {
  color: #0a73db;
}

.bg-green {
  background-color: #d9ffdc;
  color: rgb(10, 103, 46);
}
.bg-red {
  background-color: #ffd9d9;
  color: rgb(103, 10, 10);
}
.hr-line {
  height: 2px;
  width: 100%;
  background-color: #0077eeaa;
  border-radius: 10px;
}

.total-salary {
  text-align: center;
  background-color: #dbedff;
  padding: 20px;
  border-radius: 10px;
  font-size: 24px;
  font-weight: bolder;
}
.salary-word {
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
}

.loading-sec {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #0974ff 0%, #28bbff 33%, #4b27ff 100%);

  background-size: 400% 400%;
  animation: gradient 1s ease infinite;
  font-size: 24px;
}
.img-pre-wrap {
  /* width: 200px; */
  height: 200px;
  display: grid;
}
.img-upload-pre {
  width: 100%;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 3/4;
  border-radius: 10px;
  text-align: end;
}

.img-upload-sec-container {
  width: '620px';
  height: '200px';
  border: 1px solid #ccc;
}

.img-upload-section {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  /* position: center; */
  background-position: center;
  border-radius: 10px;
  /* aspect-ratio: 3/4; */
}

.upload-btn {
  background-color: #383939;
  width: 150px;
  position: relative;
  top: -35px;
}
.upload-btn:hover {
  background-color: #070707;
}
.loading-sec-wrap {
  height: 30vh;
  width: 100vw;
  display: grid;
  place-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.607);
  z-index: 100;
  backdrop-filter: blur(5px);
}
.loading-sec-wrap::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 10px;
  width: 100vw;
  background: rgb(0, 237, 255);
  background: linear-gradient(90deg, rgba(0, 237, 255, 1) 0%, rgba(0, 212, 255, 1) 55%, rgba(0, 36, 255, 1) 100%);
  animation: loading 1s infinite alternate;
  z-index: 1000 !important;
}

/* @media print {
  .to-print {
    transform: scale(0.5);
  }
} */

.unstyle-button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  color: rgb(73, 74, 74);
  font-family: arial, sans-serif;
}

@media print {
  @page {
    size: A3;
  }
}

.account-sec {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: #e2e7ef;
  border: #bed4ea 1px solid;
  border-radius: 10px;
  max-width: 250px;
}

.accound-designation {
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: left;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0a73db;
}

.emp-search-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  background-color: #e2e7ef;
  border: #bed4ea 1px solid;
  border-radius: 10px;
  margin: 10px 0px;
  width: min(90vw, 700px);
}
.emp-search-sec:hover {
  background-color: #d1dcee;
}

.asset-search-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  background-color: #e2e7ef;
  border: #bed4ea 1px solid;
  border-radius: 10px;
  margin: 10px 0px;
  /* width: min(90vw, 330px); */
}
.asset-search-sec:hover {
  background-color: #d1dcee;
}

.search-desc {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #0a73db;
}

.search-text {
  display: flex;
  align-items: center;
}
.emp-data-table {
  padding: 10px;
  border-collapse: collapse;
  border-radius: 20px;
  width: 100%;
}

.emp-data-table td {
  padding: 5px 10px;
  border: 1px solid rgba(222, 222, 223, 0.297);
}

.emp-data-table tr:nth-child(even) {
  background-color: #f2f5f9;
}
.emp-data-table tr:hover {
  background-color: #c5d2e6;
}

.req-data-table {
  padding: 10px;
  border-collapse: collapse;
  border-radius: 20px;
  width: 100%;
}

.req-data-table td {
  padding: 10px 10px;
  border: 1px solid rgba(222, 222, 223, 0.297);
}

.req-data-table tr:nth-child(even) {
  background-color: #f2f5f9;
}
.req-data-table tr:hover {
  background-color: #c5d2e6;
}

.small-info-text {
  font-size: 11px;
  padding: 5px;
  font-weight: lighter;
}

.insight-ul {
  /* padding: 10px; */
  list-style: none;
  /* list-style-image: url('star.svg'); */
  /* list-style-position: inside; */
}

.insight-li {
  padding: 15px;
  margin: 10px;
  background-color: #f2f5f9;
  border-radius: 10px;
}

.badge {
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 10px;
  min-width: 25%;
  text-align: center;
}
.badge-gr {
  background-image: linear-gradient(62deg, #09ff8c 0%, #18d262 100%);
  color: rgb(6, 59, 6);
}
.badge-yl {
  color: rgb(59, 47, 6);
  background-image: linear-gradient(62deg, #ffef09 0%, #ffa527 100%);
}

.badge-red {
  color: rgb(255, 255, 255);
  background-image: linear-gradient(62deg, #f65220 0%, #ff2739 100%);
}

.card-dx-wrap {
  background: #fff;
  color: #404040;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 5px 7px 10px 2px #064a5b2d;
  margin-bottom: 20px;

  /* height: 100%; */
}

.info-wrap {
  display: flex;
  margin: 10px;
  gap: 15px;
  align-items: center;
  font-size: 12px;
  color: #070707;
}
.cal-info-red-dot {
  background-color: rgb(250, 61, 61);
  box-shadow: 0px 0px 0px 3px rgba(250, 127, 127, 0.918), 0px 0px 10px 3px rgba(250, 127, 127, 0.918);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.cal-info-yellow-dot {
  background-color: rgb(255, 167, 16);
  box-shadow: 0px 0px 0px 3px rgba(250, 201, 54, 0.918), 0px 0px 10px 3px rgba(250, 201, 54, 0.918);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.cal-info-green-dot {
  background-color: rgb(45, 222, 112);
  box-shadow: 0px 0px 0px 3px rgba(82, 243, 194, 0.918), 0px 0px 10px 3px rgba(54, 250, 211, 0.918);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.card-dx-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  /* border: #81858729 1px solid; */
  margin: 5px;
  padding: 8px 20px;
  /* width: 250px; */
  width: 100%;
}

.card-dxx-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
  border-radius: 10px;
  /* border: #81858729 1px solid; */
  margin: 2px;
  padding: 4px 4px;
  /* width: 250px; */
  width: 100%;
  height: 100%;
}

.bg-gray-gr {
  background-image: linear-gradient(45deg, #1f1e1e 0%, #35373b 100%);
  color: #ffffff;
}
.bg-blue-gr {
  background-image: linear-gradient(45deg, #dbedff 0%, #dbedff 100%);
  color: #ffffff;
}
.bg-green-gr {
  background-image: linear-gradient(45deg, #3ee18aaa 0%, #27ffc9a1 100%);
  color: #04330f;
  box-shadow: 1px 1px 0px 0px #07a08f1f, 5px 7px 10px 2px #27e6cf63;
}
.bg-red-gr {
  background-image: linear-gradient(45deg, #e93b3e94 0%, #f2655ba3 100%);
  box-shadow: 1px 1px 0px 0px #a007071f, 5px 7px 10px 2px #e6272742;
  color: #610606;
}
.bg-yellow-gr {
  background-image: linear-gradient(45deg, #ffb7008c 0%, #f2f25ba5 100%);
  box-shadow: 1px 1px 0px 0px #e6c8061f, 5px 7px 10px 2px #e6d32763;
  color: #4f2c05;
}
.bg-gray-gray {
  background-image: linear-gradient(45deg, #7c7c7c8c 0%, #737373a5 100%);
  box-shadow: 1px 1px 0px 0px #e6c8061f, 5px 7px 10px 2px #88888863;
  color: #ffffff;
}

.bg-gray-black {
  background-image: linear-gradient(45deg, #2d2d2d8c 0%, #1d1d1da5 100%);
  box-shadow: 1px 1px 0px 0px #3131311f, 5px 7px 10px 2px #6a6a6a63;
  color: #ffffff;
}

.timeline-item {
  background-color: #f2f5f9;
  padding: 5px 20px;
  /* text-align: center; */
  color: #041c3b;
  border-radius: 10px;
  /* box-shadow: 0px 5px 10px 0px #033a712c; */
}

.timeline-item p {
  font-size: 12px;
  color: #004cb0;
}
.hr-overflow-bar {
  padding-bottom: 30px;
  overflow: scroll;
  /* max-width: 90vw; */
  width: 100%;
  max-height: 500px;
}

.last-checkin-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.file-upload-btn {
  background-color: #f1f3f6;
  border: 1px dashed #8091996c !important;
  padding: 20px 0px;
}
.section-card {
  background: #f1f3f6;
  display: grid;
  place-items: center;
  padding: 15px;
  margin-top: 15px !important;
  gap: 20px;
}
.date-range {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px !important;
}
.tkt-section-card {
  background: #f1f3f6;
  display: grid;
  place-items: center;
  padding: 10px;
}

.sm-file-upload-btn {
  background-color: #f1f3f6;
  border: 1px dashed #8091996c !important;
  font-size: 12px;
  font-weight: lighter;
  margin: 0px 10px;
}

.status-check-item {
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
}

.annual-leave-details {
  display: grid;
  place-items: center;
  padding: 20px;
  margin-top: 10px;
  background-color: #6ca9ff61;
  border-radius: 10px;
}

.fixed-h-table {
  /* overflow in y direction */
  overflow-y: scroll;
  /* overflow in x direction */
  overflow-x: hidden;
  height: 400px !important;
  width: 100%;
}

.box-red {
  /* shadow inside the component and only in left side */

  box-shadow: inset -15px 0px 0px 0px rgba(250, 127, 127, 0.918);
}

.marker {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: inline-block;
  margin-right: 5px;
  font-size: 10px;
  display: grid;
  place-items: center;
  text-align: center;
  line-height: 95%;
  padding: 10px;
}

.marker-check-out {
  background-color: rgba(255, 167, 16, 0.74);
  box-shadow: 0px 0px 0px 3px rgba(250, 201, 54, 0.918), 0px 0px 10px 3px rgba(250, 201, 54, 0.918);
}

.marker-check-in {
  color: #03182c;
  background-color: rgba(74, 173, 255, 0.74);
  box-shadow: 0px 0px 0px 3px rgba(95, 162, 255, 0.918), 0px 0px 10px 3px rgba(28, 179, 239, 0.918);
}

.attendence-map-card {
  /* Overlay map and child components*/
  position: relative;
  height: 100%;
  width: 100%;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl {
  display: none;
}

.attendence-info {
  z-index: 10;
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #f6f6f69b;
  backdrop-filter: blur(3px);
  border-radius: 10px;
  box-shadow: 5px 3px 20px 1px #2064d148;
  padding: 20px;
  z-index: 1;
  display: grid;
  place-items: center;
  overflow: hidden;
  transition: all 0.5s linear 0s !important;
}
.attendence-info:hover {
  height: auto;
  /* min-height: 150px; */
}

.attendence-info-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.attendence-info-item h5 {
  padding: 5px;
  border-radius: 10px;
}

.company-card-lg {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 5px 3px 20px 1px #2064d148;
  position: relative;
  margin-bottom: 20px;
}
.cc-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cc-logo {
  width: 200px;
  height: 80px;
  object-fit: scale-down;
  object-position: left;
}

.cc-item {
  display: grid;
  place-items: center;
  padding: 10px;

  /* background-color: #e7edf5; */
  width: 100px;
}

.cc-item-main {
  font-size: 24px;
  font-weight: bold;
}

.cc-item-sub {
  font-size: 12px;
}

.cc-md-title {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: -25px;
  left: 0;
  background-color: #f6eac7;
  padding: 0px 10px;
  border-radius: 10px 10px 0px 0px;
  z-index: -1;
}

.pile-green {
  background-color: #c7f6c7;
  padding: 2px 7px;
  border-radius: 15px;
  color: #055005;
  box-shadow: 0px 5px 10px 1px #7cebb48a;
}
.pile-red {
  background-color: #f6c7c7;
  padding: 2px 7px;
  border-radius: 15px;
  color: #500605;
  box-shadow: 0px 5px 10px 1px #eb7c7c8a;
}
.pile-yellow {
  background-color: #f6eac7;
  padding: 2px 7px;
  border-radius: 15px;
  color: #502905;
  box-shadow: 0px 5px 10px 1px #ebcc7c8a;
}

.text-pile {
  border-radius: 10px;
  display: grid;
  width: 110px;
  text-align: center;
  height: 30px;
  display: grid;
  place-items: center;
  padding: 0 !important;
}

.text-rejected {
  /* red gradient */
  background: linear-gradient(45deg, #ff1111 0%, #ff0762 100%);
  color: #f3f3f3;
  box-shadow: 0px 5px 10px 1px #ff111165;
}

.text-accepted {
  /* red gradient */
  background: linear-gradient(45deg, #3ae8b1 0%, #2bfd89 100%);
  color: #063a15;
  box-shadow: 0px 5px 10px 1px #2bfd896f;
}

.text-pending {
  /* red gradient */
  background: linear-gradient(45deg, #ffe45f 0%, #ffc814 100%);
  color: #3a1706;
  box-shadow: 0px 5px 10px 1px #fde42b74;
}

.text-appeal {
  /* red gradient */
  background: linear-gradient(45deg, #f39f7b 0%, #ff6a14 100%);
  color: #3a1606;
  box-shadow: 0px 5px 10px 1px #ef611a74;
}

.user-img-round {
  height: 220px;
  width: 220px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  object-fit: cover;
  box-shadow: 0px 0px 20px 1px #8395a79d;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

.user-table tr {
  border-bottom: 1px solid #e0e0e0;
  transition: all 0.5s linear 0s !important;
}

.user-table tr:hover {
  background-color: #e2e7ef;
}

.user-table td {
  padding: 10px;
}

.history-div {
  padding: 20px;
  border-radius: 10px;
  /* border: 1px solid #e0e0e0; */
  margin-bottom: 20px;
}

.history-div table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

.history-div table td {
  border-bottom: 1px solid #e0e0e0;
  padding: 2px;
}

.user-table h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px;
}

.user-table h2::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;

  margin-top: 0px;
  background: rgb(0, 151, 255);
  background: linear-gradient(43deg, rgb(7, 55, 86) 0%, rgba(0, 147, 255, 0) 100%);
}

.coming-soon-page {
  display: grid;
  place-items: center;
  height: 70vh;
  width: 100%;
  background-color: #f3f3f3;
  text-align: center;
}

.coming-soon-page h2 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
}

.modal-parent {
  display: grid;
  place-items: center;
  width: 100vw;
  min-height: 100vh;
}

.modal-child {
  width: min(800px, 90%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 1px #8395a79d;
}

.report-quick {
  display: grid;
  place-items: center;
  width: fit-content;
  background-color: #f0f4f9;
  padding: 50px 10px;
  margin: 30px;
}

.folder-wrap {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  text-align: center;
  width: 120px;
  min-height: 150px;
  word-wrap: break-word;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 1px #8395a737;
  transition: all 0.25s linear 0s !important;
  cursor: pointer;
  border: none;
}

.folder-wrap:hover {
  background-color: #e1e2e3;
  box-shadow: 0px 10px 20px 1px #8395a779;
}

.sm-info-card {
  background-color: rgb(255, 255, 255);
  height: 100px;
  display: grid;
  place-items: center;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 1px #8395a713;
}

.select-btn-wrap {
  display: flex;
}

.select-btn {
  background-color: #f3f3f3;
  border: none;
  width: 100%;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  transition: all 0.25s linear 0s !important;
  box-shadow: 0px 10px 20px 1px #8395a713;
}

.select-btn:hover {
  background-color: #d5e2f6;
}

.selected-btn {
  background-color: #fda22b;
}
